import React from "react";
import { Link } from "react-router-dom";

function WordLessCard({ source, title, id }) {
  return (
    <Link to={`/program/${id}`}>
      <img className="responsive-img" src={source} alt="" />
      <h5>{title} </h5>
    </Link>
  );
}

export default WordLessCard;
