import React from "react";
import "./mission.css";

function Mission() {
  return (
    <div className="lb_mission_wrap indigo darken-4">
      <div className="lb_mission_container ">
        <div className="row">
          <div className="col l6 s12">
            <h4 className="lb_mission_title"> Vision</h4>
            <p className="lb_mission_text white-text">
              Our Vision is to support needy students by Mentoring & Counseling
              them enabling them choose right career. option.
            </p>
          </div>
          <div className="col l6 s12">
            <h4 className="lb_mission_title">Mission</h4>
            <p className="lb_mission_text white-text">
              To Contribute to students society by showing them the right path
              during their "Learning" process. we aim to feel "Joy of giving" by
              providing complete information on Learning Techniques & Career
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
