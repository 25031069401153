import React from "react";

function Map() {
  return (
    <div className="col l5 offset-l1 s12">
      <iframe
      title='MY MAP'
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15544.266768443531!2d77.5483534!3d13.0949598!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcd60ebf93df62a0b!2sSamadhan%20Academy!5e0!3m2!1sen!2sin!4v1632733766745!5m2!1sen!2sin"
        width={"100%"}
        height={450}
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default Map;
