import React from "react";
import "./Slider.css";

function MySlider() {
  return (
    <div className="lb_sliderBanner">
      <div className="container">
        <div className="row">
          <div className="col ">
            <div className="lb_slide_content">
              <h3 className="white-text right">
                Success Is A Matter Of Choice Not Chance
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MySlider;
