import React, { useState } from "react";
import CertificationData from "../../assets/data/certificationData";
import WordLessCard from "../../Helpers/WordLessCard/WordLessCard";

function Certification() {
  const [data] = useState(CertificationData);
  return (
    <div className="container">
      <h3 className="center">Achievements</h3>
      <div className="row">
        {data.map((item) => {
          return (
            <div className="col l4">
              <WordLessCard source={item.imgUrl} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Certification;
