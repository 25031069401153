const WhatWeDoData = [
  {
    id: 1,
    title: "Motivational Talks",
    text: "Mistakes are great opportunities we learn from. Mistakes are stepping stones. Failure makes our Success Big & meaningful. We must find positive lessons from our every mistake.",
  },
  {
    id: 2,
    title: "Student Counseling",
    text: "In Teaching Learning Process,Counseling of the Students has very important place. We coduct Counseling Sessions for the students which help them achieve their Goals.",
  },
  {
    id: 3,

    title: "Stress Handling",
    text: "Today, Stress management is a big problem for our Students.Stress obstructs the Learning Process. Samadhan trains students to handle Stress, and focus on Learning.",
  },
  {
    id: 4,
    title: "Career Counseling",

    text: "What after X and XII ! We conduct Seminars for Students & their Parents on What Career to choose?. When & How play vital Role.",
  },
  {
    id: 5,
    title: "Orientation Sessions",
    text: "Colleges keep conducting Various Orientation Sessions for their Students. Samadhan supports Colleges conduct such Events with ease.",
  },
  {
    id: 6,
    title: "Key Note Speaker",
    text: "A Key Note Speaker add Value in all the Events an Institute counducts. Call Samadhan & allow Our Expert create Value addition in your Event for you.",
  },
];

export default WhatWeDoData;
