import React from "react";
import "./footer.css";
import Logo from "../../assets/Image/logo.png";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillYoutube,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      class="page-footer 
lb_footer"
    >
      <div class="">
        <div class="row">
          <div class="col l4 s12">
            <img width={200} className="responsive-img" src={Logo} alt="" />

            <p class="grey-text text-lighten-4">H S RAGHAV MA, M Phil</p>
            <p class="grey-text text-lighten-4">Keynote Speaker</p>
            <p class="grey-text text-lighten-4">Motivational Speaker</p>
            <p>
              <span>
                <a href="https://www.facebook.com/harisubhash.raghav.5">
                  <AiFillFacebook size={30} />
                </a>
              </span>
              <span>
                <a href="https://www.linkedin.com/in/raghav-hari-subhash-8335a021">
                  <AiFillLinkedin size={30} />
                </a>
              </span>
            </p>
          </div>
          <div class="col l4 s12">
            <h5 class="white-text">Our Events </h5>
            <p class="grey-text text-lighten-4">
              <Link className="white-text" to="/program/1">
                {" "}
                Learning Techniques
              </Link>
            </p>
            <p class="grey-text text-lighten-4">
              <Link className="white-text" to="/program/2">
                Counseling
              </Link>
            </p>
            <p class="grey-text text-lighten-4">
              <Link className="white-text" to="/program/4">
                Exam Handling
              </Link>
            </p>
            <p class="grey-text text-lighten-4">
              <Link className="white-text" to="/program/5">
                Stress Handling
              </Link>
            </p>
            <p class="grey-text text-lighten-4">
              <Link className="white-text" to="/program/6">
                Choosing Right Career After SSLC & +2.
              </Link>
            </p>
            <p>
              <span>
                <a href="https://youtube.com/user/MrRaghav405">
                  <AiFillYoutube size={30} color={"red"} />
                </a>
              </span>
              <span style={{ marginLeft: 10 }}>
                <a href="!#">
                  <AiFillTwitterCircle size={30} />
                </a>
              </span>
            </p>
          </div>
          <div class="col l4  s12">
            <h5 class="white-text">Address</h5>
            <p>No.26, 2nd Cross, Amba Bhawani Road,</p>
            <p> Sai Nagar-1 Vidyaranyapura, Bangalore - 560 097</p>
            <ul>
              <li>
                <a class="grey-text text-lighten-3" href="#!">
                  +91 98860 03080
                </a>
              </li>
              <li>
                <a class="grey-text text-lighten-3" href="#!">
                  +91 81519 87745
                </a>
              </li>
              <li>
                <a
                  class="grey-text text-lighten-3"
                  href="mailto:samadhanacademy20@gmail.com"
                >
                  samadhanacademy20@gmail.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="container">
          © 2014 Copyright Text
          <a class="grey-text text-lighten-4 right" href="#!">
            More Links
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
