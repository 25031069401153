import React, { useState } from "react";
import ProgramData from "../../assets/data/programData";
import WordLessCard from "../../Helpers/WordLessCard/WordLessCard";

function Programs() {
  const [data] = useState(ProgramData);
  return (
    <div className="container">
      <h3 className="center indigo-text darken-4-text">Programs</h3>
      <div className="row">
        {data.map((item) => {
          return (
            <div className="col l4">
              <WordLessCard
                source={item.imgUrl}
                title={item.text}
                id={item.id}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Programs;
