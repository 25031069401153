import React, { useState } from "react";
import WhatWeDoData from "../../assets/data/whatwedo";
import WordCard from "../../Helpers/WordCard/WordCard";
import "./WhatWedo.css";
import { Parallax } from "react-parallax";
import paroBg from "../../assets/Image/pax.jpeg";

function WhatWedo() {
  const [data] = useState(WhatWeDoData);
  return (
    <Parallax
      className="lb_we_do"
      bgImage={paroBg}
      bgImageAlt="the cat"
      strength={500}
    >
      <div style={{ padding: 50 }} className="container-fluid ">
        <div className="row">
          <h2 className="center lb_whatwedo_title">What We do</h2>
          {data.map((item) => {
            return (
              <div key={item.id} className=" col l6">
                <WordCard heading={item.title} text={item.text} />
              </div>
            );
          })}
        </div>
      </div>
    </Parallax>
  );
}

export default WhatWedo;
