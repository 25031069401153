import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Image/logo.png";
import "./navbar.css";
function Navbar() {
  const [click, setClick] = useState(false);
  // const history = useHistory();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <nav className="navbar z-depth-0">
      <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
        <img className="myLogo" src={Logo} alt="" />
      </Link>

      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? "fas fa-times" : "fas fa-bars"} />
      </div>

      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
            About Us
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/programmes"
            className="nav-links"
            onClick={closeMobileMenu}
          >
            Program
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
            Contact
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
            Contact Us
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/donate" className="nav-links" onClick={closeMobileMenu}>
            Danate
          </Link>
  </li>*/}
      </ul>
    </nav>
  );
}

export default Navbar;
