import React from "react";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import Mission from "../../components/Mission/Mission";
import Topbanner from "../../components/TopBanner/Topbanner";
import AbtImg from "../../assets/Image/abtImg.jpeg";
import AbtJumbo from "../../assets/Image/abtTop.jpeg";
import RadioXp from "../../assets/Image/ach_img9.jpeg";
import "./about.css";
import { IoArrowRedoOutline } from "react-icons/io5";

function AboutPage() {
  return (
    <div>
      <Topbanner title="About Us" Bg={AbtImg} />
      <Jumbotron
        Jumbo={AbtJumbo}
        title="Hari Subhash Raghav "
        text="Mr.H S Raghav, the founder of Samadhan, comes from Indian Air Force. He is PG in English with M Phil Degree. Has vast experience in education field. Having worked with Base pEducational Services for 8+years, was responsible for establishing Vidya Soudha PU College at Peenya. Mentoring & Couseling students is his expertise"
        Icon={
          <IoArrowRedoOutline color={"indigo"} style={{ marginRight: 10 }} />
        }
        service1="career counselling"
        service2="Stress Management"
        service3="Orientation Program"
        service4="Exam Handling"
        service5="Placement Assistance"
      />

      <div className="container-fluid lb_radioXp_wrap">
        <div className="row">
          <div className="col l6 s12">
            <div>
              <h4>Career Counseling on Radio</h4>

              <p>
                Samadhan Academy is proud to have associated with CEMCA
                (Commonwealth Educational Media Center for Asia), New Delhi.
                Choosing right Career at right Time is a huge Task for our
                Students, specially from Semi-Urban and Rural Areas. In a
                special Project implemented by CEMCA, Mr HS Raghav responded to
                Career related Questions sent by the students to 5 Radio
                Stations. Two Radio Stations from Rajasthan (Radio Jhunjhanu &
                Radio Bhiwadi), Two Radio Stations from Jharkhand ( Radio
                Khanchi & Radio Salesian) and one Radio Station (Purulia Radio)
                from West Bengal were part of the Project.
              </p>
              <p>
                These Radio Stations announced the Project on Radio Stations and
                the Local News Papers. The questions were collected from
                Students and sent to Samadhan Academy. Mr HS Raghav recorded the
                answers to the questions sent by students and submitted to Radio
                Stations. And then, these Solutions were broadcast on the Radio.
                Students were asked to submit their Feedback on the quality of
                Answers. And the students found these answers to be excellent.
              </p>
              <p>
                This Initiative was executed well for 6 Weeks. Radio Stations,
                CEMCA and Samadhan Academy worked together to make this
                important Task successful. The students submitted their positive
                feedback to Radio Stations.
              </p>

              <p>
                Samadhan Academy is pleased to convey sincere thanks to CEMCA,
                Radio Stations and the students who actively participated in the
                Project.
              </p>
            </div>
          </div>
          <div className="col l5 s12 offset-l1">
            <div>
              <img className="responsive-img" src={RadioXp} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Mission />
    </div>
  );
}

export default AboutPage;
