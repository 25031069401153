import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import Map from "../../components/Map/Map";
import Topbanner from "../../components/TopBanner/Topbanner";
import ContanctBg from "../../assets/Image/contanctImg.jpeg";
import Mission from "../../components/Mission/Mission";
function ContactPage() {
  return (
    <div>
      <Topbanner Bg={ContanctBg} />
      <div style={{ paddingTop: 30 }} className="container">
        <div className="row">
          <ContactForm />
          <Map />
        </div>
      </div>
      <Mission />
    </div>
  );
}

export default ContactPage;
