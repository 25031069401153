import "./App.css";
//Psd test1234
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navigationbar/Navbar";
import AboutPage from "./Pages/About/AboutPage";
import ProgramPage from "./Pages/ProgramPage/ProgramPage";
import ContactPage from "./Pages/Contact/ContactPage";
import ProgramDetailPage from "./Pages/ProgrameDetailPage/ProgramDetailPage";
import Scrolltop from "./Helpers/ScrollTop/Scrolltop";

function App() {
  return (
    <Router>
      <Scrolltop />
      <div>
        <Navbar />
        <Switch>
          <Route path="/about" exact>
            <AboutPage />
          </Route>
          <Route path="/programmes" exact>
            <ProgramPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>

          <Route path="/program/:id" children={<ProgramDetailPage />}></Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
 