import React from "react";
import { useParams } from "react-router-dom";
import Data from "../../assets/data/programData";
import "./details.css";

function ProgramDetailPage() {
  const { id } = useParams();

  // alert(Data.items[id].title);log

  //console.log(Data[parseInt(id) - 1].title);
  //   alert(id);
  const result = Data[parseInt(id) - 1];
  return (
    <div>
      <div className="container">
        <h4 className="center">{result.text}</h4>

        <img
          className="responsive-img lb_detail_img"
          src={result.imgUrl}
          alt=""
        />

        <p className="lb_detail_text">{result.para}</p>
      </div>
    </div>
  );
}

export default ProgramDetailPage;
