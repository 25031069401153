import React from "react";

import "./jumbotron.css";


function Jumbotron({
  Jumbo,
  title,
  text,
  service1,
  service2,
  service3,
  service4,
  service5,
  Icon,
}) {
  return (
    <div className="lb_jumbo_wrap ">
      <div className="">
        <div className="row">
          <div className="col l5 s12">
            <img className="responsive-img lb_jumbo_image" src={Jumbo} alt="" />
          </div>

          <div className="col l6 offset-l1 s12">
            <h4 className="lb_jumbo_title">{title}</h4>
            <p className="lb_jumbo_text">{text}</p>

            <div style={{ marginTop: 20 }}>
              <p className="lb_jumbo_text">
                <span> {Icon}</span>
                {service1}
              </p>
              <p className="lb_jumbo_text">
                <span>{Icon}</span>
                {service2}
              </p>
              <p className="lb_jumbo_text">
                <span>{Icon}</span>
                {service3}
              </p>
              <p className="lb_jumbo_text">
                <span>{Icon}</span>
                {service4}
              </p>
              <p className="lb_jumbo_text">
                <span>{Icon}</span>
                {service5}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jumbotron;
