import React from "react";
import { Link } from "react-router-dom";
import CeoImg from "../../assets/Image/ceo.jpeg";
import "./Showcase.css";

function Showcase() {
  return (
    <div style={{ marginTop: 20 }}>
      <div className="container">
        <div className="row">
          <div className="col l5 s12">
            <img className="responsive-img" src={CeoImg} alt="" />
          </div>
          <div className="col l6 s12 offset-l1">
            <div>
              <h3 className="lb_title">Learn from the most talented people.</h3>
              <p className="lb_text">
                Hari Subhash Raghav was able to make the team realize their
                hidden potential and make them dream big. The energy level he
                created was excellent. “Highly enriching and life transforming
                session! Hari Subhash Raghav is a tremendous facilitator to help
                transform your organization in unconventional ways and through
                highly personal & emotional engagement.”
              </p>

              <p className="center">
                <Link to="/about" className="btn btn-large  orange darken-3">
                  More Info
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Showcase;
