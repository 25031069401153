import React from "react";

function ContactForm() {
  return (
    <div className="col l6 s12">
      <h4>Get in Touch</h4>
      <div class="row">
        <form class="col s12">
          <div class="row">
            <div class="input-field col s6">
              <input id="first_name" type="text" class="validate" />
              <label for="first_name">Name</label>
            </div>
            <div class="input-field col s6">
              <input id="last_name" type="email" class="validate" />
              <label for="last_name">Email</label>
            </div>
          </div>
          <div class="row">
            <div class="input-field col l12 s12">
              <input id="last_name" type="email" class="validate" />
              <label for="last_name">Email</label>
            </div>
          </div>

          <div class="row">
            <div class="input-field col s12">
              <textarea id="textarea1" class="materialize-textarea"></textarea>
              <label for="textarea1">Textarea</label>
            </div>
          </div>
        </form>
        <button className="btn btn-large indigo darken-4 waves-effect waves-light">
          send message
        </button>
      </div>
    </div>
  );
}

export default ContactForm;
