import ProgramImgOne from "../../assets/Image/p_img1.jpeg";
import ProgramImgTwo from "../../assets/Image/counselling.jpeg";
import ProgramImgThree from "../../assets/Image/p_img3.jpeg";
import ProgramImgFour from "../../assets/Image/p_img4.jpeg";
import ProgramImgFive from "../../assets/Image/p_img5.jpeg";
import ProgramImgSix from "../../assets/Image/p_img6.jpeg";
import ProgramImgSeven from "../../assets/Image/ach_img9.jpeg";
import ProgramImgEight from "../../assets/Image/seminar.jpeg";
import ProgramImgNine from "../../assets/Image/placement.jpeg";

const ProgramData = [
  {
    id: 1,
    imgUrl: ProgramImgOne,
    text: "Learning Techniques",
    para: "Practice Testing, unsurprisingly, involves self-testing or taking practice tests. More than 100 years of research involving hundreds of experiments have shown the effectiveness of students taking practice tests. Students are probably least likely to use this highly useful tool because their experience with testing is of the high-stakes, formal, summative testing variety, an experience one tends to avoid if possible.",
  },
  {
    id: 2,
    imgUrl: ProgramImgTwo,
    text: "Counseling",
    para: "Counselling the student after 10th is very important because today there are many courses available in the colleges and schools. Students are confused on what course to take and what course to leave. Just imagine a 10th pass student has to choose his career with a mind full of confusion on whether to choose between Science, Maths, Commerce, Computer science and to proceed on for 11th and 12th or to choose a diploma and go to junior college.",
  },
  {
    id: 3,
    imgUrl: ProgramImgThree,
    text: "Mentoring",
    para: "Research has established a link between integrating monitoring methods into periodic classroom reviews and the later achievement of students involved in the review sessions. Daily, weekly, and monthly reviews can all enhance the learning of new material and, if they incorporate questioning and other learning probes, can call attention to areas where reteaching is needed.",
  },
  {
    id: 4,
    imgUrl: ProgramImgFour,
    text: "Exam Handling",
    para: "Board exams play an important role in the life of students and parents as well. Board exams are essential because the grades which students secure decide the future prospects of the student. There is a lot of pressure on students who are going to appear in the board exams. They go through mental stress, tension, and anxiety which ultimately result in restlessness and depression in various students.",
  },
  {
    id: 5,
    imgUrl: ProgramImgFive,
    text: "Stress Handling",
    para: "Most students experience significant amounts of stress, and this stress can take a significant toll on health, happiness, and grades. For example, a study by the American Psychological Association (APA) found that teens report stress levels similar to that of adults, meaning that they are experiencing significant levels of chronic stress, that they feel their levels of stress generally exceed their ability to cope effectively. Roughly 30% report feeling overwhelmed, depressed, or sad because of it.Stress can affect health-related behaviors like sleep patterns, diet, and exercise as well, taking a larger toll. Given that nearly half of survey respondents reported completing three hours of homework per night in addition to their full day of school work and extra curriculars for many of them, this is understandable.",
  },
  {
    id: 6,
    imgUrl: ProgramImgSix,
    text: "Choosing Right career after SSLC and PUC",
    para: "Every student in India decides his/her career post completing 10th standard. The step which they take after 10th will decide their future. Career choice after 10th is not just an easy task to decide upon. One needs to understand his strength, area of interest and then decide which stream to take to have a fruitful career. Samadhan is the strongest place to help students and parents to choose one's career.We offer a bouquet of services such as online career guidance, counselling, career guidance report, free streamline test to access student knowledge and much more",
  },
  {
    id: 7,
    imgUrl: ProgramImgSeven,
    text: "Radio Pragramme",
    para: "Every student in India decides his/her career post completing 10th standard. The step which they take after 10th will decide their future. Career choice after 10th is not just an easy task to decide upon. One needs to understand his strength, area of interest and then decide which stream to take to have a fruitful career. Samadhan is the strongest place to help students and parents to choose one's career.We offer a bouquet of services such as online career guidance, counselling, career guidance report, free streamline test to access student knowledge and much more",
  },
  {
    id: 8,
    imgUrl: ProgramImgEight,
    text: "Seminar and Workshop",
    para: "Every student in India decides his/her career post completing 10th standard. The step which they take after 10th will decide their future. Career choice after 10th is not just an easy task to decide upon. One needs to understand his strength, area of interest and then decide which stream to take to have a fruitful career. Samadhan is the strongest place to help students and parents to choose one's career.We offer a bouquet of services such as online career guidance, counselling, career guidance report, free streamline test to access student knowledge and much more",
  },
  {
    id: 9,
    imgUrl: ProgramImgNine,
    text: "Training And Placement",
    para: "Every student in India decides his/her career post completing 10th standard. The step which they take after 10th will decide their future. Career choice after 10th is not just an easy task to decide upon. One needs to understand his strength, area of interest and then decide which stream to take to have a fruitful career. Samadhan is the strongest place to help students and parents to choose one's career.We offer a bouquet of services such as online career guidance, counselling, career guidance report, free streamline test to access student knowledge and much more",
  },
];

export default ProgramData;
