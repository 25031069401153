import React from "react";
//import Bg from "../../assets/Image/abtImg.jpeg";
import "./topbanner.css";

function Topbanner({ Bg, title }) {
  return (
    <div
      style={{ backgroundImage: `url(${Bg})` }}
      className="lb_topbanner_wrap"
    >
      <div className="container">
        <div className="row">
          <div className="col l9">
            <h3 className="white-text lb_topbanner">{title}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbanner;
