import React from "react";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import Programs from "../../components/Programs/Programs";
import Topbanner from "../../components/TopBanner/Topbanner";
import ProgramBg from "../../assets/Image/programImg.jpeg";
import Mission from "../../components/Mission/Mission";
import ProgramJumbo from "../../assets/Image/jumboImg1.jpeg";

function ProgramPage() {
  return (
    <div>
      <Topbanner Bg={ProgramBg} title="Programms" />

      <Jumbotron
        Jumbo={ProgramJumbo}
        title="samadhan Academy"
        text="Samadhan Academy is started by prominent personalities & experts
              from education industry. Founders have vast experience in grooming
              students from class 8th to 12th. We understand that students need
              a lot of hand holding apart from actual teaching, learning we
              focus on making them understand the value os learning process."
      />

      <Programs />
      <Mission />
    </div>
  );
}

export default ProgramPage;
