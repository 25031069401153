import React from "react";
import "./WordCard.css";

function WordCard({ heading, text, children }) {
  return (
    <>
      <h4 className="lb_wordcard_title">{heading}</h4>
      <p className="lb_wordcard_text"> {text}</p>
    </>
  );
}

export default WordCard;
