import React from "react";
import Certification from "../../components/Certification/Certification";
import Highlight from "../../components/HighLight/Highlight";
import Mission from "../../components/Mission/Mission";
import Programs from "../../components/Programs/Programs";
import Showcase from "../../components/Showcase/Showcase";
import MySlider from "../../components/Slider/MySlider";
import WhatWedo from "../../components/WhatWedo/WhatWedo";
//import Slider from "../../components/Slider/Slider";

function Home() {
  return (
    <div>
      <MySlider />

      <Showcase />
      <WhatWedo />
      <Highlight
        title="Invite us for Seminar on Career Counseling, Motivational Classes, Board
        Exam & Stress handling"
        text=" Had good time with 150 students of 2nd PU at Ascent PU College. Career
        Counseling Seminar on 26th Oct."
      />
      <Programs />
      <Mission />
      <Certification />
    </div>
  );
}

export default Home;
