import certificationOne from "../../assets/Image/ach_img1.jpeg";
import certificationTwo from "../../assets/Image/ach_img2.jpeg";
import certificationThree from "../../assets/Image/ach_img3.jpeg";
import certificationFour from "../../assets/Image/ach_img4.jpeg";
import certificationFive from "../../assets/Image/ach_img5.jpeg";
import certificationSix from "../../assets/Image/ach_img6.jpeg";
import certificationSeven from "../../assets/Image/ach_img7.jpeg";
import certificationEight from "../../assets/Image/ach_img8.jpeg";
import certificationNine from "../../assets/Image/ach_img9.jpeg";

const CertificationData = [
  {
    id: 1,
    imgUrl: certificationOne,
  },
  {
    id: 2,
    imgUrl: certificationTwo,
  },
  {
    id: 3,
    imgUrl: certificationThree,
  },
  {
    id: 4,
    imgUrl: certificationFour,
  },
  {
    id: 5,
    imgUrl: certificationFive,
  },
  {
    id: 6,
    imgUrl: certificationSix,
  },
  {
    id: 7,
    imgUrl: certificationSeven,
  },
  {
    id: 8,
    imgUrl: certificationEight,
  },
  {
    id: 9,
    imgUrl: certificationNine,
  },
];

export default CertificationData;
