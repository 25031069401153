import React from "react";
import "./HightLight.css";

function Highlight({ title, text }) {
  return (
    <div className="indigo darken-4 lb_highlight_wrapper">
      <div className="container">
        <h5 className=" center lb_highlight_title">{title}</h5>
        <p className="white-text center lb_highlight_text ">{text}</p>
      </div>
    </div>
  );
}

export default Highlight;
